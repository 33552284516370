body {
  font: 100% sans-serif;
  margin: 15px;
}

input {
  font-size: 18px;
  outline: 0;
}

.field {
  border: 1px solid #ddd;
  display: flex;
  position: relative;
}

.field--focused {
  border-color: #ccc;
}

.input {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 10px 0 10px 10px;
  width: 100%;
}

.clear-button,
.submit-button {
  background: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0;
  cursor: pointer;
  font-size: 0;
  outline: 0;
  padding: 0;
  width: 25px;
}

.clear-button {
  background-image: url(./icons/clear.svg);
  background-size: 30%;
  right: 0;
  width: 40px;
}

.submit-button {
  background-color: #e5e5e5;
  background-image: url(./icons/search.svg);
  background-size: 35%;
  border: 1px solid #ddd;
  padding: 0 20px;
  opacity: .8;
  width: 40px;
}

.submit-button:hover {
  opacity: 1;
}

.suggestions {
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  max-height: 240px;
  overflow: auto;
  padding: 0;
  position: relative;
  top: -1px;
  user-select: none;
}

.suggestion {
  cursor: pointer;
  height: 40px;
  letter-spacing: 0.03em;
  line-height: 40px;
  padding: 0 7px 0 10px;
}

.suggestion--focused {
  background: #f5f5f5;
}

.suggestion:last-child {
  border: none;
}
