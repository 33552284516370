body {
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
    list-style:none;
    padding:0;
    margin:0;
    width:100%;
    background-color: #e0e0e0;
}

li {
	cursor:pointer;
	padding-top: 10px;
	padding-bottom: 10px;
	border: 1px gray solid;
	padding-left: 5px;
}

table {
  border: 1px solid;
}

